import React, { useState, useEffect, useRef, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { Drawer, Form } from 'antd';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Joyride from 'react-joyride';

import * as Iot from 'services/aws/iot/aws-iot';

// Icons
import { DownloadOutlined } from '@ant-design/icons';

import { truncateString, scrollTo, settlementDetailsMap } from 'resources/helpers';

import { displayAlertAction } from 'store/actions/globalActions';
import { transactionsSteps } from 'resources/landlordTourSteps';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';
import {
  getTransactionsAction,
  setSummaryPaymentType,
  unsetTransactionRenter,
} from 'store/actions/landlordActions';

import { createRefund } from 'services/api/landlordApi/create';
import {
  getTransactions,
  getSettlements,
  getFinixSettlements,
  getSettlementTransfers,
} from 'services/api/landlordApi/read';
import { deleteBillingItem } from 'services/api/landlordApi/delete';

import InputSearch from 'components/InputSearch';
import Space from 'components/Space';
import Button from 'components/Button';
import TransactionPanel from '../components/TransactionPanel';
import PropertyPanel from '../components/PropertyPanel';
import InvitationPanel from '../components/InvitationPanel';

import TransactionsTable from './components/TransactionsTable/TransactionsTable';
// import AccountProfilePanel from '../components/AccountProfilePanel';
// import AccountPreferencesPanel from '../components/AccountPreferencesPanel';
// import PlansBillingsPanel from '../components/PlansBillingsPanel';
// import BillingDetailsPanel from '../components/BillingDetailsPanel';
// import NewsPanel from '../components/NewsPanel';
// import OnboardingOverlay from '../components/OnboardingOverlay';
import OnboardingFullscreen from '../components/OnboardingFullScreen';

const Transactions = (props) => {
  const { setShowOnboarding, setDrawerVisible, showOnboardingFullscreen, setShowOnboardingFullscreen } = props;
  const dispatch = useDispatch();
  const qc = useQueryClient();
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const global = useSelector((store) => store.global);
  const transactions = useSelector((state) => state.landlord.transactions);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatusFilter, setCurrentStatusFilter] = useState(
    'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:DELAYED_PROCESSED,state:POSTED'
  );
  const [currentPortfolioFilter, setCurrentPortfolioFilter] = useState(null);
  const [showTransactionPanel, setShowTransactionPanel] = useState(false);
  const [showPropertyPanel, setShowPropertyPanel] = useState(false);
  const [showInvitationPanel, setShowInvitationPanel] = useState(false);
  const [currentTypeFilter, setCurrentTypeFilter] = useState(null);

  const [currentDateRange, setCurrentDateRange] = useState(null);
  const [typeFilterMenu, setTypeFilterMenu] = useState([]);
  const [statusFilterMenu, setStatusFilterMenu] = useState([]);
  const [dateFilterMenu, setDateFilterMenu] = useState([]);
  const [renterFilterMenu, setRenterFilterMenu] = useState([]);
  const [portfolioFilterMenu, setPortfolioFilterMenu] = useState([]);
  const [currentSortOrder, setCurrentSortOrder] = useState('-orderDate');
  const [currentSearch, setCurrentSearch] = useState(null);
  const [deleteText, setDeleteText] = useState({
    text: 'Delete Transaction',
    color: 'inherit',
    style: { color: 'inherit', fontWeight: 'inherit' },
    action: false,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [settleExpandedKeys, setSettleExpandedKeys] = useState([]);
  const [settleSelectedRows, setSettleSelectedRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const stripeTimer = useRef(null);
  let activeTab = useRef('1');
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [settlements, setSettlements] = useState({
    settlements: [],
    page: 1,
    size: 10,
    total: 0,
    order: ['createdAt', 'DESC'],
  });

  const [settlementsPagination, setSettlementsPagination] = useState({
    page: 1,
    size: 10,
    total: 0,
    sort: ['cretaedAt', 'DESC'],
    filter: [],
  });

  const [loadSettlements, setLoadsettlements] = useState(false);
  const [settlePage, setSettlePage] = useState(1);
  const [settleStatus, setSettleStatus] = useState(null);
  const [settleSort, setSettleSort] = useState('desc');
  const [settleDateRange, setSettleDateRange] = useState(null);
  // Settlements date filter state
  const [settleDateFilterMenu, setSettleDateFilterMenu] = useState([]);
  const [settleStartDate, setSettleStartDate] = useState(null);
  const [settleEndDate, setSettleEndDate] = useState(null);

  const [settleSearchText, setSettleSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const settlePageRef = useRef(1);

  // Refs

  const currentPageRef = useRef(1);
  const pageSizeRef = useRef(10);
  const currentStatusFilterRef = useRef(
    'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:DELAYED_PROCESSED,state:POSTED'
  );
  const currentTypeFilterRef = useRef(null);
  const currentRenterFilterRef = useRef(null);
  const currentSortOrderRef = useRef('-orderDate');
  const currentSearchRef = useRef(null);
  const currentDateRangeRef = useRef(null);
  const currentFieldsFilterRef = useRef(null);
  const currentPortfolioFilterRef = useRef(null);

  const [userId, setUserId] = useState('');
  const [editProperty, setEditProperty] = useState(null);

  const transactionsRef = useRef(null);

  // Invitation panel form
  const [form] = Form.useForm();
  const [editInvitationProperty, setEditInvitationProperty] = useState(false);

  const csvRef = useRef();

  const onExpand = (expanded, { key }) => {
    const keys = expandedKeys;
    const expandedK = expanded ? keys.concat(key) : keys.filter((k) => k !== key);
    setExpandedKeys(expandedK);
  };

  const onSettleExpand = (expanded, { key }) => {
    const keys = settleExpandedKeys;
    const expandedK = expanded ? keys.concat(key) : keys.filter((k) => k !== key);
    setSettleExpandedKeys(expandedK);
  };

  const resetDropdown = (items) => {
    const object = {};
    for (let i = 0; i < items.length; i++) {
      object[i] = false;
    }
    return object;
  };

  useEffect(() => {
    if (!transactions.loading && window.innerWidth < 576) {
      scrollTo(transactionsRef.current);
    }
  }, [transactions]);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));
    dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
    if (session.userData.paymentGateway === 'SilaStripe' || session.userData.paymentGateway === 'Finix') {
      fetchSettlements(true, 1, null, 'desc', null, null);
    }
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (userId !== session?.userData?.id && session?.userData?.id !== '') {
      setUserId(session?.userData?.id);
    }
  }, [session]);

  const fetchTransactions = async (
    renterFilter,
    searchFilter,
    dateRangeFilter,
    fieldsFilter,
    typeFilter,
    portfolioFilter
  ) => {
    const rFilter = renterFilter || currentRenterFilterRef.current;
    const sFilter = searchFilter || currentSearchRef.current;
    const dRFilter = dateRangeFilter || currentDateRange || currentDateRangeRef.current;
    const fFilter = fieldsFilter || currentFieldsFilterRef.current;
    const tFilter = typeFilter || currentTypeFilter || currentTypeFilterRef.current;
    const pFilter = portfolioFilter || currentPortfolioFilter;

    dispatch({
      type: 'SET_TRANSACTIONS_LOADING',
    });
    dispatch(
      getTransactionsAction(
        1,
        pageSize,
        currentStatusFilter,
        tFilter,
        rFilter,
        '-orderDate',
        sFilter,
        dRFilter,
        fFilter,
        pFilter
      )
    );
  };

  const fetchSettlements = async (loader, page, statusFilter, sortOrder, dateRange, search) => {
    try {
      if (loader) setLoadsettlements(true);
      let settlements = [];
      if (session.userData.paymentGateway === 'SilaStripe') {
        settlements = await getSettlements(
          session.cognitoTokens.idToken.jwtToken,
          page,
          10,
          statusFilter,
          sortOrder,
          dateRange,
          search
        );
      }
      if (session.userData.paymentGateway === 'Finix') {
        settlements = await getFinixSettlements(
          session.cognitoTokens.idToken.jwtToken,
          page,
          10,
          statusFilter,
          sortOrder,
          dateRange,
          search
        );
        console.log('fetched settlements', settlements);
      }
      setSettlements(settlements);
      setLoadsettlements(false);
    } catch (error) {
      setLoadsettlements(false);
      console.log(error);
    }
  };

  const handleSettleSearch = async (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setSettleSearchText(selectedKeys[0]);
    //setSearchedColumn(dataIndex);

    await fetchSettlements(true, 1, settleStatus, settleSort, settleDateRange, selectedKeys[0]);
  };

  const handleSettleReset = async (clearFilters) => {
    clearFilters();
    setSettleSearchText('');
    await fetchSettlements(true, 1, settleStatus, settleSort, settleDateRange, null);
  };

  useEffect(() => {
    dispatch({ type: 'SET_SELECTED_MENU', payload: 'transactions' });
    const params = new URLSearchParams(props.history?.location?.search);
    let renterFilter = null;
    let dateRangeFilter = null;
    let searchFilter = null;
    let fieldsFilter = null;
    let typeFilter = null;

    if (!props.history?.location?.search) {
      currentRenterFilterRef.current = null;
      currentDateRangeRef.current = null;
      currentSearchRef.current = null;
      currentFieldsFilterRef.current = null;
    }
    if (params.get('billing_account_id')) {
      const billingAccountId = params.get('billing_account_id');
      renterFilter = `billing_account_id:${billingAccountId}`;
      currentRenterFilterRef.current = renterFilter;
    }
    if (params.get('date_range')) {
      dateRangeFilter = params.get('date_range');
      setCurrentDateRange(dateRangeFilter);
      currentDateRangeRef.current = dateRangeFilter;
    }
    if (params.get('search')) {
      searchFilter = params.get('search');
      setCurrentSearch(searchFilter);
      currentSearchRef.current = searchFilter;
    }
    if (params.get('fields')) {
      fieldsFilter = params.get('fields');
      currentFieldsFilterRef.current = fieldsFilter;
    }
    if (params.get('type')) {
      typeFilter = params.get('type');
      setCurrentTypeFilter(typeFilter);
      currentTypeFilterRef.current = typeFilter;
      setTypeFilterMenu(['PAYMENT']);
    }

    fetchTransactions();
    // dispatch(getRentersListAction(1, 10, '-lastName'));
    setDropdownVisible(resetDropdown(transactions.items));
  }, [props.history?.location?.search]);

  useEffect(() => {
    Iot.attachMessageHandler((topic, message) => {
      const string = new TextDecoder().decode(message);
      const ms = JSON.parse(string);
      if (ms.event === 'TRANSACTION_UPDATE') {
        dispatch(
          getTransactionsAction(
            currentPageRef.current,
            pageSizeRef.current,
            currentStatusFilterRef.current,
            currentTypeFilterRef.current,
            currentRenterFilterRef.current,
            currentSortOrderRef.current,
            currentSearchRef.current,
            currentDateRangeRef.current,
            currentFieldsFilterRef.current,
            currentPortfolioFilterRef.current
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    if (csvData && csvRef.current && csvRef.current.link) {
      setTimeout(() => {
        csvRef.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  const handleSearch = (e) => {
    const value = e.target.value;

    if (e.key === 'Backspace' && value.length === 1) {
      setCurrentSearch('');
      currentSearchRef.current = '';
      setCurrentPage(1);
      currentPageRef.current = 1;
      dispatch({
        type: 'SET_TRANSACTIONS_LOADING',
      });
      dispatch(
        getTransactionsAction(
          1,
          pageSize,
          currentStatusFilter,
          currentTypeFilter,
          currentRenterFilterRef.current,
          currentSortOrder,
          null,
          null,
          currentFieldsFilterRef.current,
          currentPortfolioFilter
        )
      );
    }
    if (e.key === 'Enter') {
      setCurrentSearch(value);
      currentSearchRef.current = value;
      setCurrentPage(1);
      currentPageRef.current = 1;
      dispatch({
        type: 'SET_TRANSACTIONS_LOADING',
      });
      dispatch(
        getTransactionsAction(
          1,
          pageSize,
          currentStatusFilter,
          currentTypeFilter,
          currentRenterFilterRef.current,
          currentSortOrder,
          value,
          currentDateRange,
          currentFieldsFilterRef.current,
          currentPortfolioFilter
        )
      );
    }
    if (activeTab.current !== '1') {
      activeTab.current = '1';
    }
  };

  const handleDateRangeFilter = (setSelectedKeys, selectedKeys, confirm, page) => {
    // confirm();

    setDateFilterMenu(selectedKeys);

    const dateRange = `${startDate.format('MM-DD-YYYY')}:${endDate.format('MM-DD-YYYY')}`;
    setCurrentDateRange(dateRange);
    currentDateRangeRef.current = dateRange;
    dispatch({
      type: 'SET_TRANSACTIONS_LOADING',
    });
    let p = 1;
    if (page) {
      p = page;
    } else {
      p = currentPage;
    }
    dispatch(
      getTransactionsAction(
        p,
        pageSize,
        currentStatusFilter,
        currentTypeFilter,
        currentRenterFilterRef.current,
        currentSortOrder,
        currentSearchRef.current,
        dateRange,
        currentFieldsFilterRef.current,
        currentPortfolioFilter
      )
    );
  };

  const handleResetDateRangeFilter = (clearFilters, confirm) => {
    // confirm();
    // clearFilters();
    setDateFilterMenu([]);
    setStartDate(null);
    setEndDate(null);
    setCurrentDateRange(null);
    currentDateRangeRef.current = null;
    dispatch({
      type: 'SET_TRANSACTIONS_LOADING',
    });
    dispatch(
      getTransactionsAction(
        currentPage,
        pageSize,
        currentStatusFilter,
        currentTypeFilter,
        currentRenterFilterRef.current,
        currentSortOrder,
        currentSearchRef.current,
        null,
        currentFieldsFilterRef.current,
        currentPortfolioFilter
      )
    );
  };

  const handleSettleDateRangeFilter = async (setSelectedKeys, selectedKeys, confirm, page) => {
    const dateRange = `${settleStartDate.format('MM-DD-YYYY')}:${settleEndDate.format('MM-DD-YYYY')}`;
    setSettleDateRange(dateRange);
    await fetchSettlements(true, 1, settleStatus, settleSort, dateRange, settleSearchText);
  };

  const handleSettleResetDateRangeFilter = async (clearFilters, confirm) => {
    try {
      setSettleStartDate(null);
      setSettleEndDate(null);
      setSettleDateRange(null);
      await fetchSettlements(true, 1, settleStatus, settleSort, null, settleSearchText);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current ? pagination.current : 1;
    const sortOrder = sorter.order === 'ascend' ? 'orderDate' : '-orderDate';
    let statusFilter = 'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:DELAYED_PROCESSED,state:POSTED';
    // let renterFilter = null;
    let typeFilter = null;
    let portfolioFilter = null;

    if (
      filters?.status?.length &&
      filters.status !== null &&
      filters !== undefined &&
      Object.keys(filters).length
    ) {
      statusFilter = '';
      setStatusFilterMenu(filters.status);
      for (let i = 0; i < filters.status.length; i++) {
        if (i === 0) statusFilter += `state:${filters.status[i]}`;
        if (i > 0) statusFilter += `,state:${filters.status[i]}`;
      }
    }
    // if (filters.renter) {
    //   setRenterFilterMenu(filters.renter);
    //   for (let i = 0; i < filters.renter.length; i++) {
    //     if (i === 0) renterFilter = `billing_account_id:${filters.renter[i]}`;
    //     if (i > 0) renterFilter += `,billing_account_id:${filters.renter[i]}`;
    //   }
    // }

    if (filters.type !== null && filters !== undefined && Object.keys(filters).length) {
      typeFilter = currentTypeFilter || '';
      setTypeFilterMenu(filters.type);
      for (let i = 0; i < filters.type.length; i++) {
        if (i === 0) typeFilter = `${filters.type[i]}`;
        if (i > 0) typeFilter += `,${filters.type[i]}`;
      }
    }

    if (filters.portfolio !== null && filters !== undefined && Object.keys(filters).length) {
      portfolioFilter = filters.portfolio.toString();
      setPortfolioFilterMenu(filters.portfolio);
    }

    // reset portfolio filter

    setCurrentPage(page);
    currentPageRef.current = page;
    // setCurrentRenterFilter(renterFilter);
    setCurrentStatusFilter(statusFilter);
    currentStatusFilterRef.current = statusFilter;
    setCurrentTypeFilter(typeFilter);
    currentTypeFilterRef.current = typeFilter;
    setCurrentSortOrder(sortOrder);
    currentSortOrderRef.current = sortOrder;

    setCurrentPortfolioFilter(portfolioFilter);
    currentPortfolioFilterRef.current = portfolioFilter;
    setExpandedKeys([]);
    if (filters.type === null) setTypeFilterMenu([]);
    if (filters.status === null) setStatusFilterMenu([]);
    if (filters.portfolio === null) setPortfolioFilterMenu([]);

    dispatch({
      type: 'SET_TRANSACTIONS_LOADING',
    });

    let updatedPageSize = pageSize;
    if (pagination.pageSize) {
      updatedPageSize = pagination.pageSize;
      setPageSize(pagination.pageSize);
      pageSizeRef.current = pagination.pageSize;
    }

    dispatch(
      getTransactionsAction(
        page,
        updatedPageSize,
        statusFilter,
        typeFilter,
        currentRenterFilterRef.current,
        sortOrder,
        currentSearchRef.current,
        currentDateRange,
        currentFieldsFilterRef.current,
        portfolioFilter
      )
    );
  };

  const handleSettleTableChange = async (pagination, filters, sorter) => {
    let statusFilter = null;
    const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
    setSettleSort(sortOrder);

    const page = pagination.current ? pagination.current : settlePage;
    if (filters.hasOwnProperty('status')) {
      if (filters.status) {
        if (filters.status.length) {
          statusFilter = filters.status;
          setSettleStatus(statusFilter);
        }
      }
    }

    setSettlePage(page);
    await fetchSettlements(true, page, statusFilter, sortOrder, settleDateRange);
  };

  const handleMobileFilter = (filter) => {
    let filterName = '';
    switch (filter) {
      case 'Credit':
        filterName = 'CREDIT';
        setTypeFilterMenu([filterName]);
        break;
      case 'Payment':
        filterName = 'PAYMENT';
        setTypeFilterMenu([filterName]);
        break;
      case 'Security Deposit':
        filterName = 'SECURITY_DEPOSIT';
        setTypeFilterMenu([filterName]);
        break;
      case 'Fee':
        filterName = 'MISC_FEE';
        setTypeFilterMenu([filterName]);
        break;
      case 'Rent Charge':
        filterName = 'RENT';
        setTypeFilterMenu([filterName]);
        break;
      case 'Refund':
        filterName = 'REFUND';
        setTypeFilterMenu([filterName]);
        break;
      case 'Utility':
        filterName = 'UTILITY';
        setTypeFilterMenu([filterName]);
        break;
      default:
        filterName = null;
        setTypeFilterMenu([]);
        break;
    }
    let typeFilter = '';
    if (filterName) typeFilter = `${filterName}`;
    setExpandedKeys([]);
    dispatch({
      type: 'SET_TRANSACTIONS_LOADING',
    });
    dispatch(
      getTransactionsAction(
        currentPage,
        pageSize,
        currentStatusFilter,
        typeFilter,
        currentRenterFilterRef.current,
        currentSortOrder,
        currentSearchRef.current,
        currentFieldsFilterRef.current,
        currentPortfolioFilter
      )
    );
  };

  const itemType = (item) => {
    switch (item.billingCharge.chargeType) {
      case 'SECURITY_DEPOSIT':
        return 'Security Deposit';
      case 'DEPOSIT':
        return 'Deposit';
      case 'CHARGE':
        return 'Charge';
      case 'RENT':
        return 'Rent charge';
      case 'CHARGEBACK':
        return 'Chargeback';
      case 'UTILITY':
        return 'Utility';
      case 'PAYMENT':
        return 'Payment';
      case 'REFUND':
        return 'Refund';
      case 'CREDIT':
        return 'Credit';
      case 'MISC_FEE':
        return 'Fee';
      case 'LATE_FEE':
        return 'Late Fee';
      case 'DISCOUNT':
        return 'Discount';
      case 'PARKING':
        return 'Parking';
      case 'MOVE_IN':
        return 'Move In';
      case 'MOVE_OUT':
        return 'Move Out';
      default:
        return;
    }
  };

  const handleRefund = async (paymentId, renterId) => {
    await createRefund(session.cognitoTokens.idToken.jwtToken, paymentId, +session.userData.id, renterId);
    dispatch(
      getTransactionsAction(
        currentPage,
        pageSize,
        currentStatusFilter,
        currentTypeFilter,
        currentRenterFilterRef.current,
        currentSortOrder,
        currentSearchRef.current,
        currentFieldsFilterRef.current,
        currentPortfolioFilter
      )
    );
    setDropdownVisible(resetDropdown(transactions.items));
    setExpandedKeys([]);
  };

  const handleClickDelete = async (e, id) => {
    // if (!deleteText.action) {
    //   return setDeleteText({
    //     text: 'Really Delete?',
    //     style: { color: '#CB48B7', fontWeight: 500 },
    //     action: true,
    //   });
    // }
    try {
      // set button state to loading

      await deleteBillingItem(id);
      // setDropdownVisible(resetDropdown(transactions.items));

      dispatch({
        type: 'SET_TRANSACTIONS_LOADING',
      });
      dispatch(
        getTransactionsAction(
          currentPage,
          pageSize,
          currentStatusFilter,
          currentTypeFilter,
          currentRenterFilterRef.current,
          currentSortOrder,
          currentSearchRef.current,
          currentFieldsFilterRef.current,
          currentPortfolioFilter
        )
      );
      setExpandedKeys([]);
      // setDeleteText({
      //   text: 'Delete Transaction',
      //   color: 'inherit',
      //   action: false,
      // });
      dispatch(displayAlertAction('', 'success', 'Transaction Deleted', true, 5000));
    } catch (error) {
      // setDropdownVisible(resetDropdown(transactions.items));
      // setDeleteText({
      //   text: 'Delete Transaction',
      //   color: 'inherit',
      //   action: false,
      // });
      setExpandedKeys([]);
      dispatch(
        displayAlertAction(
          'Delete Transaction Error',
          'warning',
          error?.data?.errors[0].title || 'Your transaction was not deleted. Please try again.',
          true,
          5000
        )
      );
    }
  };

  const getLandlordFee = (payment) => {
    let landlordFee = 0;
    if (payment.fee.landlordAbsorbPercent > 0 && +payment.fee.amount > 0) {
      landlordFee = (+payment.fee.amount / 100) * payment.fee.landlordAbsorbPercent;
    }
    return landlordFee.toFixed(2);
  };

  const getCsvData = async () => {
    try {
      setLoadingCsv(true);
      const result = await getTransactions(
        1,
        200,
        currentStatusFilter,
        currentTypeFilter,
        currentRenterFilterRef.current,
        currentSortOrder,
        currentSearchRef.current,
        currentDateRange,
        null,
        currentPortfolioFilter
      );

      const numberOfRequests = Math.ceil(result.totalCount / 500);
      let moreResults = [];
      if (numberOfRequests > 1) {
        const fetchData = [];
        for (let i = 0; i < numberOfRequests; i++) {
          fetchData.push(
            getTransactions(
              i + 2,
              30,
              currentStatusFilter,
              currentTypeFilter,
              currentRenterFilterRef.current,
              currentSortOrder,
              currentSearchRef.current,
              currentDateRange,
              null,
              currentPortfolioFilter
            )
          );
        }
        moreResults = await Promise.all(fetchData);

        for (let i = 0; i < moreResults.length; i++) {
          result.items = [...result.items, ...moreResults[i].items];
        }
      }

      const header = [
        'Date',
        'Type',
        'Address',
        'Unit',
        'Renter',
        'Amount',
        'Status',
        'Transaction ID',
        'Method',
        'Your Fee',
        'Settlement Date',
        'Net Settlement',
        'Memo',
      ];

      const transactionData = result.items.map((r) => {
        let status = '';
        if (r.state === 'PROCESSED') {
          status = 'processed';
        } else if (r.state === 'NOT_PROCESSED') {
          status = 'failed';
        } else if (r.state === 'PROCESSING') {
          status = 'pending';
        } else if (r.state === 'DELAYED_PROCESSED') {
          status = 'Scheduled';
        } else if (r.state === 'POSTED') {
          status = 'Posted';
        }
        // r.payment ? getFee() : '0'
        const array = [
          moment(r.orderDate).format('MM-DD-YYYY'),
          r.billingCharge ? itemType(r) : r.refType,
          r?.property[0]?.address,
          r?.property[0]?.address2,
          `${r?.customer[0]?.firstName} ${r.customer[0]?.lastName}`,
          r.billingCharge ? r.billingCharge.amount.toFixed(2) : r.payment.amount,
          status,
          r.id,
          r.payment ? r.payment.paymentMethodType : '',
          r.payment ? getLandlordFee(r.payment) : '0.00',
          moment(r.orderDate).format('MM-DD-YYYY'),
          '0.00',
          r.memo ? r.memo : '',
        ];
        return array;
      });

      setCsvData([header, ...transactionData]);
      setLoadingCsv(false);
    } catch (error) {
      setLoadingCsv(false);
      console.log(error);
    }
  };

  const handleSettlementSelect = (record, selected, selectedRows, nativeEvent) => {
    // console.log(record, selected, selectedRows, nativeEvent);
    setLoadingCsv(true);
    const url = process.env.REACT_APP_API_URL_V3 + '/finix' + record.transfersUri;
    qc.ensureQueryData(['finixTransfers', record.id], () => getSettlementTransfers(cognitoToken, url))
      .then(() => setLoadingCsv(false))
      .catch((err) => {
        console.lerror(err);
        setLoadingCsv(false);
      });

    setSettleSelectedRows(selectedRows);
  };

  const getSettlementCsv = async (selected) => {
    console.log('getSettlementCsv', selected);
    setLoadingCsv(true);
    const header = [
      'Transaction Date',
      'Settle Date',
      'Transaction ID',
      'Settlement ID',
      'Tenant',
      'Property',
      'Amount',
      'Fee',
      'Net Settlement',
      'Type',
    ];
    let transactionData = [];
    for (const select of selected) {
      // console.log('select', select);
      const transactions = settlementDetailsMap(qc.getQueryData(['finixTransfers', select.id]), select.finixId);
      // console.log('transactions', transactions);
      transactionData.push(...transactions);
    }

    transactionData = transactionData
      .sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt) || b.settlementId.localeCompare(a.settlementId)
      )
      .map((t) => {
        const arr = [
          moment(t.createdAt).format('YYYY-MM-DD'),
          moment(t.settledAt).format('YYYY-MM-DD'),
          t.id,
          t.settlementId,
          t.renterFullname,
          t.renterFulladdress,
          t.amount,
          t.fee,
          t.netAmount,
          t.type,
        ];
        return arr;
      });

    console.log('transactionData', transactionData);
    setCsvData([header, ...transactionData]);
    setLoadingCsv(false);
  };

  // const getSettlementCsv = async () => {
  //   try {
  //     setLoadingCsv(true);
  //     const result = await getSettlements(
  //       session.cognitoTokens.idToken.jwtToken,
  //       1,
  //       10,
  //       settleStatus,
  //       settleSort,
  //       settleDateRange,
  //       settleSearchText
  //     );

  //     const numberOfRequests = Math.ceil(result.total / 500);
  //     let moreResults = [];

  //     if (numberOfRequests > 1) {
  //       const fetchData = [];
  //       for (let i = 0; i < numberOfRequests; i++) {
  //         fetchData.push(
  //           getSettlements(
  //             session.cognitoTokens.idToken.jwtToken,
  //             i + 2,
  //             30,
  //             settleStatus,
  //             settleSort,
  //             settleDateRange,
  //             settleSearchText
  //           )
  //         );
  //       }

  //       moreResults = await Promise.all(fetchData);

  //       for (let i = 0; i < moreResults.length; i++) {
  //         result.settlements = [...result.settlements, ...moreResults[i].settlements];
  //       }
  //     }

  //     const header = [
  //       'Date',
  //       'Settled',
  //       'Status',
  //       'id',
  //       'Property Group',
  //       'Payments',
  //       'Adjustments',
  //       'Fees',
  //       'Net Deposit',
  //       'Payment ids',
  //       'Charge ids',
  //     ];

  //     const transactionData = result.settlements.map((s) => {
  //       const arr = [
  //         s.createdAt ? moment(s.createdAt).format('MM/DD/YYYY') : '',
  //         s.settlementDate ? moment(s.settlementDate).format('MM/DD/YYYY') : '',
  //         s.status,
  //         s.id,
  //         s.propertyGroupName,
  //         s.grossSettlement,
  //         s.totalCharges,
  //         s.totalSettlementFees,
  //         s.netSettlement,
  //         s.payments.map((p) => p.paymentId).join(', '),
  //         s.charges.map((c) => c.chargeId).join(', '),
  //       ];
  //       return arr;
  //     });
  //     setCsvData([header, ...transactionData]);
  //     setLoadingCsv(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoadingCsv(true);
  //   }
  // };

  const handleJoyrideCallback = async (data) => {
    const { status, type, action } = data;

    if (action === 'close' || action === 'skip') {
      dispatch({
        type: 'SET_SHOW_TOUR',
        payload: {
          showMainTour: false,
        },
      });
    }
  };

  const onTabClick = (key) => {
    activeTab.current = key;
    forceUpdate();
  };

  return (
    <>
      <StyledDrawer
        closable={false}
        // closeIcon={<CloseSquareFilled style={{ fontSize: '25px', color: '#122C34' }} />}
        placement="right"
        destroyOnClose
        onClose={() => {
          props.setDrawerVisible(false);
          props.setShowOnboarding(false);
          props.setShowAccountProfile(false);
          props.setShowAccountPreferences(false);
          props.setShowPlansBillingPanel(false);
          props.setShowBillingDetailsPanel(false);
          form.resetFields();
          setShowTransactionPanel(false);
          setEditInvitationProperty(false);
          setShowPropertyPanel(false);
          setShowInvitationPanel(false);
          dispatch(setSummaryPaymentType(''));
          dispatch(unsetTransactionRenter());
        }}
        open={props.drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {/* props.drawerVisible && props.showOnboarding && (
          <Onboarding
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            checkLandlordStatus={checkLandlordStatus}
            updatePaymentGateway={updatePaymentGateway}
          />
        ) */}
        {props.drawerVisible && showTransactionPanel && (
          <TransactionPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
            fetchTransactions={fetchTransactions}
            editProperty={editProperty}
            setEditProperty={setEditProperty}
          />
        )}
        {props.drawerVisible && showPropertyPanel && (
          <PropertyPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPropertyPanel={setShowPropertyPanel}
            editProperty={editProperty}
            editInvitationProperty={editInvitationProperty}
            setShowInvitationPanel={setShowInvitationPanel}
          />
        )}
        {props.drawerVisible && showInvitationPanel && (
          <InvitationPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowInvitationPanel={setShowInvitationPanel}
            setEditProperty={setEditProperty}
            setShowPropertyPanel={setShowPropertyPanel}
            form={form}
            editInvitationProperty={editInvitationProperty}
            setEditInvitationProperty={setEditInvitationProperty}
            setShowTransactionPanel={setShowTransactionPanel}
          />
        )}
        {/*  Moved to LandlordMenu.js */}
        {/* {props.drawerVisible && props.showAccountProfile && (
          <AccountProfilePanel
            setShowAccountProfile={props.setShowAccountProfile}
            setDrawerVisible={props.setDrawerVisible}
          />
        )}
        {props.drawerVisible && props.showAccountPreferences && (
          <AccountPreferencesPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowAccountPreferences={props.setShowAccountPreferences}
          />
        )}
        {props.drawerVisible && props.showPlansBillingPanel && (
          <PlansBillingsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )}
        {props.drawerVisible && props.showBillingDetailsPanel && (
          <BillingDetailsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )} */}
      </StyledDrawer>
      {/* Removed from UI 4.0.0 */}
      {/* <NewsPanel
        title="What's New @PayRent"
        placement="right"
        closable={true}
        onClose={() => {
          props.setNewsDrawerVisible(false);
        }}
        open={props.newsDrawerVisible}
        posts={false}
        isOpen={props.newsDrawerVisible}
      /> */}
      {/* showOnboardingOverlay && (
        <OnboardingOverlay
          showOnboardingOverlay={showOnboardingOverlay}
          setShowOnboardingOverlay={setShowOnboardingOverlay}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
        />
      ) */}
      {showOnboardingFullscreen && (
        <OnboardingFullscreen
          showOnboardingFullscreen={showOnboardingFullscreen}
          setShowOnboardingFullscreen={setShowOnboardingFullscreen}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
          stripeTimer={stripeTimer}
          // checkLandlordStatus={checkLandlordStatus}
        />
      )}
      <section className="screen screen--dashboard" data-testid="transactionsScreenTest">
        {/* MODALS */}
        {/*<OnboardingModal open={currentModal === 'onboarding'} cancel={cancelModal} />*/}
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          // getHelpers={getHelpers}
          run={global.tour.showMainTour && !props.drawerVisible && !props.showOnboarding}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={transactionsSteps}
          styles={{
            options: {
              zIndex: 19,
            },
          }}
        />

        {/* <Cards
          setDrawerVisible={props.setDrawerVisible}
          setShowTransactionPanel={setShowTransactionPanel}
          setShowPropertyPanel={setShowPropertyPanel}
          setShowInvitationPanel={setShowInvitationPanel}
        /> */}
        <Space vertical={20} />
        <StyledDiv>
          {csvData.length ? (
            <CSVLink
              ref={csvRef}
              data={csvData}
              filename={activeTab.current === '1' ? 'payrent-transactions.csv' : 'settlements.csv'}
              style={{ display: 'none' }}
            />
          ) : undefined}
          <StyledButton
            inline
            type="default"
            height={36}
            paddingtop={4}
            paddingbottom={4}
            bottom={0}
            data-testid="downloadCsvTest"
            onClick={() => {
              if (activeTab.current === '1') {
                getCsvData();
              } else {
                getSettlementCsv(settleSelectedRows);
              }
            }}
            loading={loadingCsv}
            className="downloadButtonTour"
          >
            <DownloadOutlined />
            Download CSV
          </StyledButton>
          <Space vertical={10} />
          <InputSearch placeholder="Search" mobilewidth={100} onkeydown={handleSearch} />
        </StyledDiv>
        <Space vertical={25} />
        <div ref={transactionsRef} id="anchor">
          <TransactionsTable
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleDateRangeFilter={handleDateRangeFilter}
            handleResetDateRangeFilter={handleResetDateRangeFilter}
            transactions={transactions}
            handleTableChange={handleTableChange}
            itemType={itemType}
            currentPage={currentPage}
            truncateString={truncateString}
            handleClickDelete={handleClickDelete}
            deleteText={deleteText}
            setDeleteText={setDeleteText}
            dropdownVisible={dropdownVisible}
            setDropdownVisible={setDropdownVisible}
            resetDropdown={resetDropdown}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            handleMobileFilter={handleMobileFilter}
            typeFilterMenu={typeFilterMenu}
            renterFilterMenu={renterFilterMenu}
            statusFilterMenu={statusFilterMenu}
            dateFilterMenu={dateFilterMenu}
            setDrawerVisible={props.setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
            portfolioFilterMenu={portfolioFilterMenu}
            handleRefund={handleRefund}
            setCurrentPage={setCurrentPage}
            currentPageRef={currentPageRef}
            loadSettlements={loadSettlements}
            settlements={settlements}
            settleExpandedKeys={settleExpandedKeys}
            setSettleExpandedKeys={setSettleExpandedKeys}
            setExpandedKeys={setExpandedKeys}
            setSettleSelectedRows={setSettleSelectedRows}
            handleSettlementSelect={handleSettlementSelect}
            onSettleExpand={onSettleExpand}
            settlePage={settlePage}
            handleSettleTableChange={handleSettleTableChange}
            activeTab={activeTab}
            onTabClick={onTabClick}
            settleStartDate={settleStartDate}
            setSettleStartDate={setSettleStartDate}
            settleEndDate={settleEndDate}
            setSettleEndDate={setSettleEndDate}
            setSettlePage={setSettlePage}
            settlePageRef={settlePageRef}
            handleSettleDateRangeFilter={handleSettleDateRangeFilter}
            handleSettleResetDateRangeFilter={handleSettleResetDateRangeFilter}
            settleDateFilterMenu={settleDateFilterMenu}
            handleSettleSearch={handleSettleSearch}
            handleSettleReset={handleSettleReset}
            setSettleSearchText={setSettleSearchText}
            settleSearchText={settleSearchText}
            searchedColumn={searchedColumn}
          />
        </div>
      </section>
    </>
  );
};

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 1500px) {
        width: 460px !important;
      }
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
    input {
      color: #595959;
    }
    @media screen and (max-height: 750px) {
      .ant-steps-small .ant-steps-item-description {
        font-size: 12px !important;
      }
    }
    @media screen and (max-height: 750px) {
      .ant-drawer-body h1 {
        font-size: 35px !important;
      }
      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }
  `}
`;

const StyledButton = styled(Button)`
  @media screen and (max-width: 768px) {
    padding-top: 2px;
    padding-bottom: 2px;
    height: 31px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export default Transactions;
