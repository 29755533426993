import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Form, Input, Row, Col, Select, Checkbox, Tooltip, Spin } from 'antd';

import { getPropertyGroupOnboardingStatus } from 'services/api/landlordApi/read';

import Tag from 'components/Tag';
import Text from 'components/Text';

const MerchantAccountDetails = (props) => {
  const {
    propertyGroup,
    setShowAcceptPayments,
    setShowPortfolioSettings,
    setShowPaymentDetails,
    setDrawerExpanded,
    setOnboardingState,
    onboardingState,
    onboardingStatusParser,
  } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const paymentGateway = session.userData.paymentGateway;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (propertyGroup.length) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getPropertyGroupOnboardingStatus(cognitoToken, propertyGroup[0].id);
      setOnboardingState(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const merchantStatus = (status) => {
    let st = '';
    // console.log(status);
    if (status) {
      if (session.userData.paymentGateway === 'Wepay') {
        if (status.wepayRegistrationState !== 'finished') {
          st = status.wepayRegistrationState;
        } else {
          st = status.wepayState;
        }
      }
      if (session.userData.paymentGateway === 'SilaStripe') {
        st = status?.ach?.state || null;
        const parsed = onboardingStatusParser(status);
        if (parsed) st = parsed;
      }

      if (session.userData.paymentGateway === 'Finix') {
        st = status.finixOnboardingState;
      }
    }

    switch (st) {
      case null:
      case 'NOT_STARTED':
      case 'new2-step1':
      case 'new2-step2':
      case 'IDENTITY_CREATED':
      case 'INSTRUMENT_CREATED':
        return (
          <Row gutter={[16, 16]} className="general-settings">
            <Col span={14}>
              <Tag color="">Payments Not Enabled</Tag>
            </Col>

            <Col span={10}>
              <Text
                size={14}
                color="violet"
                underline
                pointer
                onClick={() => {
                  if (paymentGateway === 'Finix') {
                    setDrawerExpanded(true);
                  }
                  setShowAcceptPayments(true);
                  setShowPortfolioSettings(false);
                }}
              >
                Accept Payments
              </Text>
            </Col>
          </Row>
        );
      case 'error':
      case 'FAILED':
      case 'REGISTER_ERROR':
      case 'REJECTED':
        return (
          <Row gutter={[16, 16]} className="general-settings">
            <Col span={24}>
              <Tag color="red">Payments Disabled</Tag>
            </Col>

            <Col span={8}></Col>
          </Row>
        );
      case 'DOCUMENTS_REQUIRED':
      case 'REVIEW':
        return (
          <Row gutter={[16, 16]} className="general-settings">
            <Col span={14}>
              <Tag color="yellow" textColor="red">
                Payments Action Required
              </Tag>
            </Col>

            <Col span={10}>
              <Text
                size={14}
                color="violet"
                underline
                pointer
                onClick={() => {
                  // setShowPaymentDetails(true);
                  if (paymentGateway === 'SilaStripe') {
                    setShowPortfolioSettings(false);
                    setShowAcceptPayments(true);
                  }
                }}
              >
                Update
              </Text>
            </Col>
          </Row>
        );
      case 'ACTION_REQUIRED':
      case 'UPDATE_REQUESTED':
      case 'active2':
        return (
          <>
            <Row gutter={[16, 16]} className="general-settings">
              <Col span={14}>
                <Tag color="yellow" textColor="red">
                  Payments Action Required
                </Tag>
              </Col>
              {/* <Col span={10}>
                <Text
                  size={14}
                  color="violet"
                  underline
                  pointer
                  onClick={() => {
                    setShowPortfolioSettings(false);
                    setShowAcceptPayments(true);
                  }}
                >
                  Contact Customer Support
                </Text>
              </Col> */}
            </Row>
          </>
        );
      case 'ACTIVE':
      case 'active':
      case 'APPROVED':
        return (
          <>
            <Row gutter={16} className="general-settings">
              <Col span={14}>
                <Tag color="green">Payments Enabled</Tag>
              </Col>

              <Col span={10}>
                <Text
                  size={14}
                  color="violet"
                  underline
                  pointer
                  onClick={() => {
                    setShowPortfolioSettings(false);
                    setShowPaymentDetails(true);
                  }}
                >
                  Account Details
                </Text>
              </Col>
            </Row>
          </>
        );
      case 'DOCUMENTS_RECEIVED':
        return (
          <>
            <Row gutter={16} className="general-settings">
              <Col span={14}>
                <Tag color="gold">Payments Pending</Tag>
              </Col>

              <Col span={10}>
                <Text
                  size={14}
                  color="violet"
                  underline
                  pointer
                  onClick={() => {
                    setShowAcceptPayments(true);
                    setShowPortfolioSettings(false);
                  }}
                >
                  Details
                </Text>
              </Col>
            </Row>
          </>
        );
      case 'PAYMENT_METHODS_PENDING':
      case 'PROVISIONING':
        return (
          <>
            <Row gutter={16} className="general-settings">
              <Col span={14}>
                <Tag color="gold">Payments Pending</Tag>
              </Col>

              <Col span={10}>
                <Text
                  size={14}
                  color="violet"
                  underline
                  pointer
                  onClick={() => {
                    setShowPortfolioSettings(false);
                    setShowPaymentDetails(true);
                  }}
                >
                  Details
                </Text>
              </Col>
            </Row>
          </>
        );
      case 'ACCOUNT_CLOSED':
        return (
          <>
            <Row gutter={16} className="general-settings">
              <Col span={14}>
                <Tag color="red">Account Closed</Tag>
              </Col>
            </Row>
          </>
        );
      default:
        return (
          <Row gutter={[16, 16]} className="general-settings">
            <Col span={14}>
              <Tag color="">Payments Not Enabled</Tag>
            </Col>

            <Col span={10}>
              <Text
                size={14}
                color="violet"
                underline
                pointer
                onClick={() => {
                  if (paymentGateway === 'Finix') {
                    setDrawerExpanded(true);
                  }
                  setShowAcceptPayments(true);
                  setShowPortfolioSettings(false);
                }}
              >
                Accept Payments
              </Text>
            </Col>
          </Row>
        );
    }
  };

  return (
    <>
      <Spin spinning={loading}>{merchantStatus(onboardingState)}</Spin>
    </>
  );
};

export default MerchantAccountDetails;
