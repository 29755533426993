import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';
import styled from 'styled-components';
import { Alert, Row, Col, Select, Divider, Space as AntSpace, Switch, Tooltip } from 'antd';

import { updatePropertyGroup } from 'services/api/landlordApi/update';
import { updateSettlementBank, createPaymentMethodV3 } from 'services/api/landlordApi/create';
import { updateFinixMerchant } from 'services/api/landlordApi/update';
import FinixPaymentMethodForm from 'screens/Landlord/components/FinixPaymentMethodForm/FinixPaymentMethodForm';

import {
  getLandlordPaymentMethods,
  getPlaidLinkToken,
  getPropertyGroupOnboardingStatus,
} from 'services/api/landlordApi/read';

import { CloseSquareFilled, BankOutlined, InfoCircleFilled, DeleteOutlined } from '@ant-design/icons';

import AlertFade from 'components/AlertFade';
import PanelFooter from 'components/Panel/PanelFooter';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import Tag from 'components/Tag';
import PaymentMethodActions from './components/PaymentMethodActions';
import CreditCardStatus from './components/CreditCardStatus';
import DeleteMenu from './components/DeleteMenu';

// import './styles.scss';

const Title = styled.h1`
  font-family: Poppins;
  font-size: 42px;
  text-align: center;
  color: #122c34;
  margin-bottom: 10px !important;
`;

const Subtitle = styled.div`
  width: 320px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  color: #122c34;
`;

const { Option } = Select;

const PaymentDetails = (props) => {
  const {
    setDrawerVisible,
    setShowPaymentDetails,
    onboardingState,
    propertyGroup,
    fetchPropertyGroups,
    fetchPropertyGroupStatus,
    setOnboardingState,
  } = props;

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  const [pmSelect, setPmSelect] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [plaidToken, setPlaidToken] = useState(null);
  const [pendingMicrodeposit, setPendingMicrodeposit] = useState(false);
  const [settlementLoading, setSettlementLoading] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [changeBank, setChangeBank] = useState(false);
  const [paymentInstrument, setPaymentInstrument] = useState(null);
  const [deleteText, setDeleteText] = useState({
    text: 'Close Account',
    action: false,
  });

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  const AccountStatusTag = () => {
    let color, text;
    const key = propertyGroup[0]?.finixAccount?.onboardingState;

    switch (key) {
      case 'APPROVED':
        color = 'green';
        text = 'Approved';
        break;
      case 'UPDATE_REQUESTED':
        color = 'yellow';
        text = 'Update Requested';
        break;
      case 'REJECTED':
        color = 'red';
        text = 'Rejected';
        break;
      case 'PROVISIONING':
        color = 'yellow';
        text = 'Provisioning';
        break;
      default:
        color = 'yellow';
        text = 'Unknown';
        break;
    }

    return (
      <Tag data-testid="accountStatusTag" color={color}>
        {text}
      </Tag>
    );
  };

  const BankStatusTag = () => {
    let color, text;

    switch (paymentInstrument?.bankAccountValidationCheck) {
      case 'VALID':
        color = 'green';
        text = 'Valid!';
        break;
      case 'INVALID':
        color = 'red';
        text = 'Invalid';
        break;
      case 'NOT_ATTEMPTED':
        color = 'default';
        text = 'Pending';
        break;
      default:
        color = 'yellow';
        text = 'Inconclusive';
        break;
    }

    return (
      <Tag data-testid="bankStatusTag" color={color}>
        {text}
      </Tag>
    );
  };

  const onSuccess = async (token, metadata) => {
    try {
      setSettlementLoading(true);
      setProcessing(true);
      const plaid = {
        token,
        metadata,
      };

      await createPaymentMethodV3(cognitoToken, plaid, 'SilaStripe', 'landlord', propertyGroup[0].id);
      const groupStatus = await getPropertyGroupOnboardingStatus(cognitoToken, propertyGroup[0].id);
      setPmSelect(false);
      setOnboardingState(groupStatus);
      setProcessing(false);
      setSettlementLoading(false);
    } catch (error) {
      setSettlementLoading(false);
      console.log(error);
      setProcessing(false);
    }
  };

  const onFinixChange = async () => {
    try {
      const groupStatus = await getPropertyGroupOnboardingStatus(cognitoToken, propertyGroup[0].id);
      setPmSelect(false);
      setOnboardingState(groupStatus);
      setProcessing(false);
      setSettlementLoading(false);
    } catch (error) {
      setSettlementLoading(false);
      console.log(error);
      setProcessing(false);
    }
  };

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
  };
  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (session.userData.paymentGateway === 'SilaStripe') {
      getPaymentMethods();
      if (
        onboardingState.ach.silaSettlement?.micro_deposits_flow &&
        onboardingState.ach.silaSettlement?.meta_data?.ACTIVE?.state === 'pending verification' &&
        onboardingState.ach.silaSettlement?.payment_method_state === 'SCHEDULED'
      ) {
        setLoading(true);
        setPendingMicrodeposit(true);
        fetchPlaidToken();
      } else {
        setPendingMicrodeposit(false);
      }
    }
    if (session.userData.paymentGateway === 'Finix') {
      const availableInstrument = onboardingState?.finixPaymentInstruments?.filter(
        (instrument) => instrument.enabled
      )[0];
      if (availableInstrument) {
        setPaymentInstrument(availableInstrument);
      }
    }
  }, [onboardingState]);

  const getPaymentMethods = async () => {
    try {
      const pm = await getLandlordPaymentMethods(cognitoToken);
      const pms = pm.filter((p) => p.paymentMethodState !== 'DELETED');
      const setDefault = pms.map((p) => {
        const defGroup = p.propertyGroups.filter((pg) => +pg.groupId === +propertyGroup[0].id);
        if (defGroup.length) {
          p.default = true;
          return p;
        }
        p.default = false;
        return p;
      });
      setPaymentMethods(setDefault);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPlaidToken = async () => {
    try {
      const id = onboardingState.ach.silaSettlement.id;
      const res = await getPlaidLinkToken(cognitoToken, id);
      setPlaidToken(res?.linkToken);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAchTagStatus = (achStatus) => {
    let achSt =
      session.userData.paymentGateway === 'SilaStripe'
        ? achStatus.ach.state
        : session.userData.paymentGateway === 'Finix'
        ? achStatus.finixOnboardingState
        : achStatus.wepayState.toUpperCase();

    if (session.userData.paymentGateway === 'SilaStripe') {
      if (achStatus.ach.state === 'PASSED' || achStatus.ach.state === 'REGISTERED') {
        if (achStatus.ach.silaSettlement) {
          if (achStatus.ach.silaSettlement.payment_method_state === 'ACTIVE') {
            achSt = 'ACTIVE';
          }
          if (
            achStatus.ach.silaSettlement.payment_method_state === 'SCHEDULED' &&
            achStatus.ach?.silaSettlement?.micro_deposits_flow === 1
          ) {
            if (achStatus.ach.silaSettlement.meta_data.PENDING.state === 'pending verification') {
              achSt = 'ACTION_REQUIRED';
            }
          }
        }
      }
    }

    switch (achSt) {
      case 'ACTIVE':
      case 'APPROVED':
        return <Tag color="green">Payments Enabled</Tag>;
      case 'NOT_STARTED':
        return <Tag color="">Payments Not Enabled</Tag>;
      case 'SUCCESS':
      case 'PROVISIONING':
        return <Tag color="gold">Payments Pending</Tag>;
      case 'ACTION_REQUIRED':
      case 'DOCUMENTS_REQUIRED':
      case 'UPDATE_REQUESTED':
      case 'REVIEW':
        return (
          <Tag color="yellow" textColor="red">
            Payments Action Required
          </Tag>
        );
      case 'REGISTER_ERROR':
      case 'FAILED':
      case 'REJECTED':
        return <Tag color="red">Payments Disabled</Tag>;
      default:
        return;
    }
  };

  const getAchDetails = (achStatus) => {
    if (achStatus?.ach?.state === 'DOCUMENTS_REQUIRED' || achStatus?.ach?.state === 'REVIEW') {
      return (
        <Text size={16} color="violet" underline>
          Details
        </Text>
      );
    }
  };

  const handleExpressSettlement = async (checked) => {
    try {
      setSwitchLoading(true);
      const settings = propertyGroup[0].settings;
      settings.expressSettlement = checked;
      settings.portfolioName = propertyGroup[0].name;
      await updatePropertyGroup(cognitoToken, propertyGroup[0].id, settings);
      setSwitchLoading(false);
    } catch (e) {
      setSwitchLoading(false);
    }
  };

  const handleSelectPm = async (value, option) => {
    try {
      setSelectOpen(false);
      setSettlementLoading(true);
      await updateSettlementBank(cognitoToken, value, +session.userData.id, +propertyGroup[0].id);
      const groupStatus = await getPropertyGroupOnboardingStatus(cognitoToken, propertyGroup[0].id);
      setPmSelect(false);
      setOnboardingState(groupStatus);
      setSettlementLoading(false);
    } catch (e) {
      setSettlementLoading(false);
    }
  };

  return (
    <DrawerContainer>
      <Container>
        <Header data-testid="accountProfilePanelTest">
          <CloseSquareFilled
            style={{ fontSize: '25px', color: '#122C34' }}
            onClick={() => {
              setDrawerVisible(false);
              setShowPaymentDetails(false);
            }}
            data-testid="closePanelTest"
          />
        </Header>
        <Title>Payment Details</Title>
        <Subtitle>Payment processing settings and status</Subtitle>
        <Space vertical={10} />
        {session.userData.paymentGateway === 'Finix' ? (
          <ContentContainer data-testid="finixDataInfo">
            <AntSpace direction="vertical" size="middle" style={{ width: '100%' }}>
              <Text size={20} color="black" weight={500}>
                Merchant Account Status
              </Text>
              <Row>
                <Col span={20}>Account Status:</Col>
                <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <AccountStatusTag />
                </Col>
              </Row>
              <Row>
                <Col span={20}>Payment Status:</Col>
                <Col
                  data-testid="processingEnabledTag"
                  span={4}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  {onboardingState.finixProcessingEnabled ? (
                    <Tag color="green">Enabled</Tag>
                  ) : (
                    <Tag color="red">Disabled</Tag>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={20}>Settlements Status:</Col>
                <Col
                  data-testid="settlementEnabledTag"
                  span={4}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  {onboardingState.finixSettlementEnabled ? (
                    <Tag color="green">Enabled</Tag>
                  ) : (
                    <Tag color="red">Disabled</Tag>
                  )}
                </Col>
              </Row>

              {onboardingState.finixMerchantVerification?.raw &&
                onboardingState.finixMerchantVerification.raw.map((reason, i) => (
                  <Alert
                    showIcon
                    message={`${reason.description}. Please contect customer support`}
                    type="warning"
                  />
                ))}

              <Row justify="end" style={{ width: '100%' }}>
                <Col>
                  <Button
                    loading={settlementLoading}
                    data-testid="closeFinixAccountButton"
                    icon={<DeleteOutlined />}
                    danger
                    onClick={async () => {
                      if (!deleteText.action) {
                        return setDeleteText({
                          text: 'Really close? This cannot be undone',
                          action: true,
                        });
                      }
                      setSettlementLoading(true);
                      setProcessing(true);
                      await updateFinixMerchant(cognitoToken, onboardingState.finixMetadata.id, {
                        processing_enabled: false,
                        tags: {
                          closed_by: 'customer',
                        },
                      });
                      setAlert({
                        isVisible: true,
                        message: 'Your account has been closed',
                        type: 'warning',
                      });
                      setTimeout(function () {
                        onFinixChange();
                        setDrawerVisible(false);
                        setShowPaymentDetails(false);
                        setAlert({
                          isVisible: false,
                          message: '',
                          type: 'warning',
                        });
                      }, 3000);
                    }}
                  >
                    {deleteText.text}
                  </Button>
                </Col>
              </Row>
              <Text size={16} color="black" weight={400}>
                Settlement Bank
              </Text>
              <Row justify="space-between">
                <Col data-testid="bankLabel" span={10}>
                  <Text color="black">{paymentInstrument?.maskedAccountNumber || 'No Bank'}</Text>
                </Col>
                <Col span={8}>
                  <BankStatusTag />
                </Col>
                <Col span={6}>
                  <Button data-testid="bankAccountChange" type="link" onClick={() => setChangeBank(!changeBank)}>
                    {changeBank ? 'Cancel' : 'Change'}
                  </Button>
                </Col>
              </Row>
              {changeBank && (
                <Row data-testid="bankAccountInput">
                  <FinixPaymentMethodForm
                    merchantIdentity={{
                      id: onboardingState.id,
                      merchantIdentityId: onboardingState.finixMetadata.identity,
                    }}
                    setPaymentInstrument={setPaymentInstrument}
                    replace={true}
                    next={() => setChangeBank(false)}
                    type={'bank'}
                  />
                </Row>
              )}
            </AntSpace>
          </ContentContainer>
        ) : (
          <ContentContainer className="content-container payment-details">
            <Text size={20} color="black" centered weight={500}>
              ACH Transfer Acceptance
            </Text>
            <Space vertical={20} />
            <Row justify="center" gutter={[0, 20]}>
              <Col span={20}>{getAchTagStatus(onboardingState)}</Col>
              <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                {getAchDetails(onboardingState)}
              </Col>
            </Row>
            <Space vertical={10} />
            {session.userData.paymentGateway === 'SilaStripe' && (
              <>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Text size={16} weight={500} color="black">
                      Settlement Bank
                    </Text>
                  </Col>
                </Row>
                <Space vertical={10} />
                <Row justify="center" gutter={[0, 40]}>
                  <Col span={20}>
                    {pmSelect && session.userData.paymentGateway === 'SilaStripe' ? (
                      <Select
                        style={{ width: '80%' }}
                        placeholder="Select Settlement Bank"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <AntSpace
                              align="center"
                              style={{ padding: '0 8px 4px', display: 'flex', justifyContent: 'center' }}
                              onClick={open}
                            >
                              <BankOutlined style={{ fontSize: '14px' }} />
                              <Text size={16} color="black" pointer>
                                Add a bank account
                              </Text>
                            </AntSpace>
                            <Space vertical={10} />

                            <DeleteMenu
                              paymentMethods={paymentMethods}
                              setDropdownVisible={setDropdownVisible}
                              dropdownVisible={dropdownVisible}
                              getPaymentMethods={getPaymentMethods}
                              setAlert={setAlert}
                              groupId={propertyGroup[0].id}
                            />
                          </>
                        )}
                        onChange={handleSelectPm}
                        loading={settlementLoading}
                        disabled={settlementLoading}
                        open={selectOpen}
                        onClick={(e) => {
                          if (!selectOpen) setSelectOpen(true);
                        }}
                        // onClick={onClickStopProg}
                        onSelect={() => {
                          if (selectOpen) setSelectOpen(false);
                        }}
                        onMouseLeave={() => {
                          if (selectOpen) setSelectOpen(false);
                          if (dropdownVisible) {
                            setDropdownVisible(false);
                          }
                        }}
                      >
                        {paymentMethods.map((pm) => (
                          <Option
                            value={pm.id}
                            key={pm.id}
                            disabled={pm.microDepositsFlow && pm.paymentMethodState === 'SCHEDULED'}
                          >
                            {pm.label}
                            {!!pm.microDepositsFlow && pm.paymentMethodState === 'SCHEDULED' && ' - verifying...'}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Text size={16} color="black">
                        {onboardingState?.ach?.silaSettlement?.meta_data?.ACTIVE &&
                        session.userData.paymentGateway === 'SilaStripe'
                          ? `Account ending in ${onboardingState.ach.silaSettlement.meta_data.ACTIVE.account_last_four}`
                          : ''}
                      </Text>
                    )}
                  </Col>
                  <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {!pendingMicrodeposit && (
                      <Text
                        color={paymentMethods.length > 1 ? 'violet' : 'grey'}
                        size="14"
                        underline
                        onClick={() => {
                          if (paymentMethods.length > 1) setPmSelect(true);
                        }}
                        pointer
                      >
                        Change
                      </Text>
                    )}

                    {pendingMicrodeposit && (
                      <PaymentMethodActions
                        paymentMethods={paymentMethods}
                        pendingMicrodeposit={pendingMicrodeposit}
                        plaidToken={plaidToken}
                        onboardingState={onboardingState}
                        loading={loading}
                        setAlert={setAlert}
                        setPmSelect={setPmSelect}
                        propertyGroup={propertyGroup}
                        setProcessing={setProcessing}
                        fetchPropertyGroups={fetchPropertyGroups}
                        fetchPropertyGroupStatus={fetchPropertyGroupStatus}
                      />
                    )}
                  </Col>
                </Row>
                <Space vertical={10} />
                <Row justify="center" gutter={[0, 0]}>
                  <Col span={20}>
                    <Text size={16} weight={400} color="black">
                      Express Settlement {session.userData.servicePlan.expressFeeRate * 100}%
                      {session.userData.servicePlan.expressFeeRate > 0 ? ' ($15 max.)' : ''}
                    </Text>
                    <Tooltip
                      placement="top"
                      title="Get deposits faster. Transfers are sent in an expedited batch for processing on the same day."
                      trigger="click"
                    >
                      <InfoCircleFilled style={{ marginLeft: '8px' }} />
                    </Tooltip>
                  </Col>
                  <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Switch
                      checked={propertyGroup[0].settings?.expressSettlement || false}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onChange={handleExpressSettlement}
                      loading={switchLoading}
                      style={{ backgroundColor: '#cb48b7' }}
                    />
                  </Col>
                </Row>
                <Space vertical={20} />
                <Text size={20} color="black" centered weight={500}>
                  Credit Card Acceptance
                </Text>
                <Space vertical={20} />
                <CreditCardStatus ccStatus={onboardingState} propertyGroup={propertyGroup} />

                {/**
               <Row justify="center" gutter={[0, 20]}>
                <Col span={20}>
                  <Tag color="yellow" textColor="red">
                    Payments Action Required
                  </Tag>
                </Col>
                <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Text size={16} color="violet" underline>
                    Details
                  </Text>
                </Col>
              </Row>
               */}
              </>
            )}
          </ContentContainer>
        )}
        <PanelFooter>
          <AlertFade
            isVisible={!!alert.message}
            message={alert.message}
            // description={alert.description}
            type={alert.type}
            // clickFunction={alert.clickFunction}
            alertPosition="absolute"
            position="absolute"
            minHeight="80"
            bottom={82}
          />
          <Button
            color="violet"
            size="large"
            alignment="center"
            bottom={0}
            onClick={() => {
              setDrawerVisible(false);
              setShowPaymentDetails(false);
            }}
            loading={processing}
          >
            CLOSE
          </Button>
        </PanelFooter>
      </Container>
    </DrawerContainer>
  );
};

const DrawerContainer = styled.div`
  max-width: 100%;
  height: 100%;
  border-radius: 24px;

  .ant-form-item {
    margin-bottom: 14px;
  }
  .ant-input,
  .ant-select-selection-item,
  input {
    font-size: 16px;
  }
  .ant-select-selection-item {
    font-size: 16px;
  }
  .ant-select-selector {
    height: 31px !important;
    color: #595959;
  }

  @media screen and (max-height: 750px) {
    .ant-form-item.save-form-item {
      margin-bottom: 0px !important;
    }
  }
`;

const Header = styled.div`
  color: white;
  height: 27px;
  display: flex;
  align-items: center;
  padding-left: 2px;
  font-weight: 600;
`;

const Container = styled.div`
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
`;

const ContentContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 50px 0px 50px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

export default PaymentDetails;

// {
//   "jsonapi": {
//       "version": "1.0"
//   },
//   "data": {
//       "type": "default",
//       "id": "35",
//       "attributes": {
//           "finixSettlementEnabled": true,
//           "finixProcessingEnabled": true,
//           "finixOnboardingState": "APPROVED",
//           "finixBoardingStatusUpdatedAt": "2024-01-02T15:44:19.000Z",
//           "finixPaymentInstruments": [
//               {
//                   "id": "PIpx1hD5dC25cSUYjvY5t3AP",
//                   "createdAt": "2024-01-02T15:43:20.83Z",
//                   "updatedAt": "2024-01-02T15:43:20.83Z",
//                   "application": "APea93VCqiwJ4Fk3m3i8viEX",
//                   "createdVia": "API",
//                   "currency": "USD",
//                   "disabledCode": null,
//                   "disabledMessage": null,
//                   "enabled": true,a
//                   "fingerprint": "FPR7qriAnzHA6VRwgsq7Zf8nz",
//                   "identity": "IDqKiwbwFX83ywMjnkzeXmjU",
//                   "instrumentType": "BANK_ACCOUNT",
//                   "accountType": "BUSINESS_CHECKING",
//                   "bankAccountValidationCheck": "NOT_ATTEMPTED",
//                   "bankCode": "987654321",
//                   "country": "USA",
//                   "institutionNumber": null,
//                   "maskedAccountNumber": "XXX2121",
//                   "name": "Carlton van Putten",
//                   "transitNumber": null,
//                   "tags": {},
//                   "type": "BANK_ACCOUNT"
//               }
//           ],
//           "finixMetadata": {
//               "id": "MUwXqVRdZk3mnJf6i9Q8EzQR",
//               "mcc": "6513",
//               "mid": "FNXc3er11ebsj97Y57EXqpuH3",
//               "tags": {
//                   "landlordId": "8159",
//                   "propertyGroupId": "1960"
//               },
//               "country": "USA",
//               "gateway": "DUMMY_V1",
//               "identity": "IDqKiwbwFX83ywMjnkzeXmjU",
//               "processor": "DUMMY_V1",
//               "createdAt": "2024-01-02T15:43:40.15Z",
//               "currencies": [
//                   "USD"
//               ],
//               "updatedAt": "2024-01-02T15:44:01.23Z",
//               "application": "APea93VCqiwJ4Fk3m3i8viEX",
//               "verification": "VIp7RRXv3trSpmmcducBrcdc",
//               "merchantName": "Test",
//               "merchantProfile": "MPa5YNMBDqYBJMUucVWh3a5V",
//               "onboardingState": "APPROVED",
//               "cardCvvRequired": false,
//               "processorDetails": {
//                   "mid": "FNXc3er11ebsj97Y57EXqpuH3",
//                   "apiKey": "secretValue"
//               },
//               "processingEnabled": true,
//               "settlementEnabled": true,
//               "surchargesEnabled": false,
//               "readyToSettleUpon": "PROCESSOR_WINDOW",
//               "rentSurchargesEnabled": false,
//               "feeReadyToSettleUpon": "PROCESSOR_WINDOW",
//               "grossSettlementEnabled": false,
//               "convenienceChargesEnabled": false,
//               "cardExpirationDateRequired": false,
//               "settlementFundingIdentifier": "UNSET",
//               "disbursementsAchPullEnabled": false,
//               "disbursementsAchPushEnabled": false,
//               "disbursementsCardPullEnabled": false,
//               "disbursementsCardPushEnabled": false,
//               "levelTwoLevelThreeDataEnabled": false,
//               "creatingTransferFromReportEnabled": false,
//               "defaultPartialAuthorizationEnabled": false
//           },
//           "finixMerchantVerification": null
//       }
//   }
// }
