import React from 'react';
import { ReactComponent as Finished } from 'assets/images/onboarding-fullscreen/finished.svg';
import { ReactComponent as Failed } from 'assets/images/onboarding-fullscreen/problem.svg';
import { ReactComponent as Pending } from 'assets/images/onboarding-fullscreen/hourglass.svg';
import Text from 'components/Text/Text';
import SpaceResponsive from 'components/SpaceResponsive';

const StatusMessage = (props) => {
  const getContent = (status) => {
    const normalizeStatus = status ? status.toUpperCase() : '';
    const content = {
      title: '',
      message: '',
      image: undefined,
    };
    switch (normalizeStatus) {
      case 'ACTIVE':
      case 'APPROVED':
      case 'COMPLETED':
        content.image = <Finished />;
        content.title = 'You’re All Set!';
        content.message =
          'Congratulations on completing all the steps for setting up your account. Please let us know how we can help make your experience the best it can be.';
        return content;
      case 'NOT_STARTED':
        content.image = <Failed />;
        content.title = 'Hmmmmm...';
        content.message =
          "Somehow you ended up here without starting the onboarding process. We've logged this error and will look into it.";
        return content;
      case 'WAITING':
      case 'PENDING':
      case 'UPDATE_REQUESTED':
      case 'PROVISIONING':
        content.image = <Pending />;
        content.title = 'Thank You!';
        content.message =
          'Your account approval could take up to 24 hours. While you’re waiting, you can continue to add properties and renters.';
        return content;
      case 'DECLINED':
      case 'FAILED':
      case 'REJECTED':
        content.image = <Failed />;
        content.title = 'Uh Oh...';
        content.message =
          'Unfortunately, we were unable to approve your merchant account. Feel free to contact us for further details.';
        return content;
      default:
        content.image = <Failed />;
        content.title = 'Uh Oh...';
        content.message =
          'Unfortunately, we were unable to approve your merchant account. Feel free to contact us for further details.';
        return content;
    }
  };
  return (
    <>
      {getContent(props.status).image}
      <SpaceResponsive lg={10} md={10} sm={10} />
      <Text size={24} sizeXs={20} centered color="black" strong>
        {getContent(props.status).title}
      </Text>
      <SpaceResponsive lg={10} md={10} sm={10} />
      <Text size={16} sizeXs={12} centered color="black" width={300} margin="0 auto">
        {getContent(props.status).message}
      </Text>
    </>
  );
};

export default StatusMessage;
